import Vue from 'vue'
import App from './App.vue'

//import axios from 'axios';


import Echo from 'laravel-echo'
window.io = require('socket.io-client');


if (typeof window.io !== 'undefined') {
  if(window.location.hostname.includes("localhost")){
    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: 'localhost:6001',
    });
  }else{
    //web.meinelohnabrechnung.at
    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: window.location.hostname
    }); 
  }
}else{
  console.log("window.io is undefined, can not use io-socket");
}





Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
