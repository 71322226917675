<template>
  <div class="pausenplan">
    <div class="overlayMain" v-if="!MAINTENANCE && showIdentify"></div>
    <div class="overlayText" v-if="!MAINTENANCE && showIdentify">
      {{ pausenplanIdentifier }}
    </div>

    <div class="maintenance" v-if="MAINTENANCE">
      <div style="color: #fff; text-align: center;font-size: 80px;padding-top: 100px;">
        <i class="fas fa-exclamation-triangle fa-5x"></i><br /><br />
        Wartungsarbeiten<br />
        <div style="font-size: 42px">
          <br />
          Wir arbeiten daran, dass der Service bald wieder verfügbar ist.
        </div>
      </div>
    </div>

    <div class="content" id="content" v-if="!MAINTENANCE">
      <div class="header" v-bind:style="{ 'padding-top': headerPadding + 'px' }">
        <div class="header-image">
          <img style="width: 50px; height: 50px" src="images/mz-logo.png" />
        </div>
        <div class="header-text">
          <img style="width: 177px; height: 50px" src="images/mz-logo-text-white.png" />
        </div>
        <div class="time-backdrop"></div>
      </div>

      <div class="position" v-for="position in positions" :key="position.id" v-if="showPosition(position)">
        <div class="outer">
          <div
            class="header"
            v-bind:style="{
              'background-color': position.color,
              color: GetContrastingColor(position.color)
            }"
          >
            {{ GetPositionName(position) }}
          </div>
          <div
            style="
              display: inline-block;
              overflow: hidden;
              width: calc(100% - 305px);
              height: 30px;
            "
            v-bind:style="{ 'background-color': '#fff' }"
          >
            <!-- position.color -->
          </div>
        </div>

        <div
          class="outer employee-column"
          v-for="(employee, index) in position.employees"
          :key="employee.id"
          v-if="employee.show"
          v-bind:style="{
            height: rowHeightCurrent + 'px',
            opacity: EmployeeShiftEnded(employee) ? actualShiftEndedOpacity : ''
          }"
        >
          <div
            class="employee"
            v-bind:class="{
              'overtime-show': (employee.not_logged_in && employee.notLoggedInMinutes <= 45) || employee.not_logged_out || (!employee.not_logged_in && employee.additionalPauseMandatory),
              'pause-show': GetEmployeePauseCurrent(employee)
            }"
            style="display: inline-block; overflow: hidden"
          >
            <div class="bg" style="">
              <div
                v-if="employee.extern"
                style="
                  position: absolute;
                  background-color: rgb(189, 108, 23);
                  text-align: center;
                  color: #fff;
                "
                v-bind:style="[GetExternMarkerStyle()]"
              >
                <i class="fas fa-location-arrow" v-bind:style="[GetExternMarkerIconStyle()]" style="position: static; display: block"></i>
              </div>
              <div style="float: left">
                <img
                  v-bind:src="GetProfilePicture(employee)"
                  style="
                    width: 40px;
                    height: 40px;
                    margin: 5px 10px 5px 5px;
                    border-radius: 100px;
                  "
                  v-bind:style="[GetEmployeePictureStyle()]"
                />
              </div>
              <div class="firstname" v-if="rowHeightCurrent >= 35" v-bind:style="[{ color: employee.underage ? '#d62d2d' : '#404040' }, GetEmployeeNameStyle('first'), GetEmployeeNameMargin()]">
                {{ GetEmployeeFirstName(employee) }}
              </div>
              <div class="lastname" v-if="rowHeightCurrent >= 35" v-bind:style="[{ color: employee.underage ? '#d62d2d' : '#404040' }, GetEmployeeNameStyle('last'), GetEmployeeNameMargin()]">
                {{ GetEmployeeLastName(employee) }}
              </div>
              <div class="name" v-if="rowHeightCurrent < 35" v-bind:style="[GetEmployeeNameMargin()]">
                <div class="firstname" v-bind:style="[{ color: employee.underage ? '#d62d2d' : '#404040' }, GetEmployeeNameStyle('first')]">
                  {{ GetEmployeeFirstName(employee) }}
                </div>
                <div class="lastname" v-bind:style="[{ color: employee.underage ? '#d62d2d' : '#404040' }, GetEmployeeNameStyle('last')]">
                  {{ GetEmployeeLastName(employee) }}
                </div>
              </div>
              <div class="fas fa-clock" v-if="employee.not_logged_in && employee.notLoggedInMinutes <= 45" v-bind:style="[GetIconSize()]"></div>
              <div class="fas" v-if="GetEmployeePauseCurrent(employee)" v-bind:class="[GetEmployeePauseCurrent(employee).duration >= 30 * 60 ? 'fa-utensils' : 'fa-coffee']" v-bind:style="[GetIconSize()]"></div>
              <div class="pause-time" v-if="GetEmployeePauseCurrent(employee)">
                {{ GetPauseTimeRemainingText(GetEmployeePauseCurrent(employee)) }}
              </div>
              <div class="name-shadow"></div>
            </div>
          </div>

          <div class="timebar" v-bind:style="GetTimeBarHeight('bar')">
            <div
              style="
                position: relative;
                height: 50px;
                top: 0px;
                background-color: #fff;
                left: 0px;
                width: 0px;
              "
            ></div>

            <!-- Rotation -->
            <div
              v-for="rota in employee.rotas"
              :key="rota.id + 'rota'"
              class="rotation"
              v-bind:style="[
                {
                  left: rota.rota_left + 'px',
                  width: rota.rota_width + 'px',
                  background: customPlanShiftColorEnabled ? customPlanShiftColor : ''
                },
                GetTimeBarHeight('outer')
              ]"
            ></div>
            <!-- Legal Pause Underage -->
            <div
              v-for="rota in employee.rotas"
              :key="'p' + rota.id"
              class="rotation"
              style="background-color: #d9d9d9"
              v-bind:style="[
                {
                  left: rota.pauseLegal_left + 'px',
                  width: rota.pauseLegal_width + 'px',
                  background: !employee.not_logged_in && employee.additionalPauseMandatory && !loadingPauses ? legalPauseErrorColor : customLegalPauseColorEnabled ? customLegalPauseColor : defaultLegalPauseColor,
                  opacity: !employee.not_logged_in && employee.additionalPauseMandatory && !loadingPauses ? (1 + Math.sin(opacityAnimation)) / 2 : ''
                },
                GetTimeBarHeight('outer')
              ]"
            ></div>
            <!-- Spot Color -->
            <div
              v-for="rota in employee.rotas"
              :key="rota.id + 'rotaColorOverlay'"
              class="rotation"
              v-bind:style="[
                {
                  left: rota.rota_left + 'px',
                  width: rota.rota_width + 'px',
                  background: spotPlanShiftColorEnabled ? position.color : '',
                  opacity: spotPlanShiftColorEnabled ? 0.15 : 0
                },
                GetTimeBarHeight('outer')
              ]"
            ></div>
            <!-- Planpause -->
            <div
              v-for="pause in employee.rotas.filter((s) => s.planPauseStart)"
              :key="'plannedPause' + pause.id"
              class="rotation"
              v-bind:style="[
                {
                  left: (pause.planPauseStart > lowerBound ? pause.planPauseStart - lowerBound : 0) * secondPerPixel + 'px',
                  width: (pause.planPauseStart > lowerBound ? pause.planPauseDuration : pause.planPauseStart - lowerBound + pause.planPauseDuration) * secondPerPixel + 'px',
                  background: 'rgba(0,0,0,.55)',
                  opacity: spotPlanShiftColorEnabled ? 0.55 : 0.55
                },
                GetTimeBarHeight('outer')
              ]"
            ></div>

            <!-- ActualShift -->
            <div
              v-for="shift in employee.shifts"
              :key="shift.id"
              class="shift"
              v-bind:style="[
                {
                  left: shift.shift_left + 'px',
                  width: shift.shift_width + 'px',
                  opacity: EmployeeActualShiftNotStarted(employee) ? actualShiftNotStartedOpacity : '',
                  background: spotActualShiftColorEnabled ? position.color : ''
                },
                GetTimeBarHeight('inner')
              ]"
            >
              <div class="start" v-bind:style="[{ color: shift.shift_left === 0 ? 'transparent' : '' }, GetIconSize()]">
                {{ shift.start_hr }}
              </div>
              <div
                class="end"
                v-bind:style="[
                  {
                    color: !shift.end ? 'transparent' : '',
                    right: '-' + GetShiftOvertime(employee, shift).width
                  },
                  GetIconSize()
                ]"
              >
                {{ shift.end_hr }}
              </div>
              <div class="shift-overtime" v-bind:style="GetShiftOvertime(employee, shift)"></div>
            </div>

            <!-- Überstunden -->
            <div
              v-for="overtime in employee.overtimes"
              :key="overtime.id"
              style="
                position: relative;
                height: 35px;
                margin-top: -35px;
                background-color: rgb(182, 20, 66);
              "
              v-bind:style="[
                {
                  left: overtime.overtime_left + 'px',
                  width: overtime.overtime_width + 'px'
                },
                GetTimeBarHeight('inner')
              ]"
            ></div>
            <!-- PAUSE -->
            <div
              v-for="pause in employee.pauses"
              :key="pause.id"
              class="pause"
              v-bind:style="[
                {
                  left: pause.pause_left + 'px',
                  width: pause.pause_width + 'px'
                },
                GetTimeBarHeight('inner')
              ]"
            >
              <i v-if="pause.duration >= 1800 && pause.pause_left > 5" v-bind:style="[GetPauseIconSize()]" v-bind:class="[pause.duration >= 30 * 60 ? 'fas fa-utensils' : 'fas fa-coffee']"></i>
            </div>

            <div
              v-if="employee.birthday"
              class="rotation"
              style="
                text-align: center;
                border-radius: 500px;
                border: 1px solid #888;
              "
              v-bind:style="[
                {
                  left: '3px',
                  'margin-top': '0px',
                  width: (GetTimeBarHeightValue('outer') / 10) * 8 + 'px',
                  background: '#e0e0e0',
                  'line-height': (GetTimeBarHeightValue('outer') / 10) * 8 + 'px',
                  height: (GetTimeBarHeightValue('outer') / 10) * 8 + 'px'
                }
              ]"
            >
              <i class="fas fa-birthday-cake fa-lg" style="text-align: center; color: #5c5c5c" v-bind:style="[GetIconSize()]"></i>
            </div>


            
            <div
              v-for="rota in employee.rotas"
              v-if="showRestingArea && rota.legalPauseRest11_show && employee.shifts.length > 0"
              :key="rota.id + 'rotaLegalPause'"
              class="rotation stripes legalRest11"
              v-bind:style="[
                {
                  left: rota.legalPauseRest11_left + 'px',
                  width: rota.legalPauseRest11_width + 'px',
                  'line-height': (GetTimeBarHeightValue('outer') /8) * 8 + 'px',
                },
                GetTimeBarHeight('outer'),
              ]"
            ></div>
            
            <div
              v-for="rota in employee.rotas"
              v-if="showRestingArea && rota.legalPauseRest11_show && employee.shifts.length > 0"
              :key="rota.id + 'rotaLegalPause'"
              style="background-color:#555; text-align:center;"
              class="rotation legalRest11"
              v-bind:style="[
                {
                  left: (rota.legalPauseRest11_left + 10) + 'px',
                  width: (rota.legalPauseRest11_width/3) + 'px',
                  'line-height': (GetTimeBarHeightValue('outer') /20) * 8 + 'px',
                  'height': (GetTimeBarHeightValue('outer') /20) * 8 + 'px',
                },
              ]"
            >11 Stunden Ruhepause</div>




            <!-- White Background cause the following div laying on top has a transparency -->
            <!--<div v-if="(employee.not_logged_in && employee.notLoggedInMinutes <= 45) || employee.not_logged_out || (!employee.not_logged_in && employee.additionalPauseMandatory && !loadingPauses)" style="position: relative; height: 50px; margin-top:-50px; background-color: #fff; z-index: 9998; left:4px;width:300px;"></div>-->
            <div
              v-if="(employee.not_logged_in && employee.notLoggedInMinutes <= 45) || employee.not_logged_out || (false && !employee.not_logged_in && employee.additionalPauseMandatory && !loadingPauses)"
              style="
                position: relative;
                height: 100%;
                margin-top: -50px;
                z-index: 9999;
                left: 4px;
                width: 300px;
              "
              v-bind:style="[
                { 'background-color': '#da2055' },
                {
                  left: GetRedBarLeft(employee),
                  width: GetRedBarWidth(employee)
                }
              ]"
            >
              <div style="width: 300px; height: 50px; overflow-y: hidden">
                <div
                  class="alertMessageIcon"
                  v-bind:style="[
                    {
                      opacity: alertIconOpacity,
                      display: !employee.not_logged_in && employee.additionalPauseMandatory && !loadingPauses ? 'none' : 'flex'
                    },
                    GetTimeBarIcon()
                  ]"
                  style="
                    position: absolute;
                    left: 5px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 20px;
                    width: 20px;
                    font-size: 20px;
                    overflow-y: hidden;
                    background-color: #fff !important;
                    text-align: center;
                    border-radius: 20px;
                    color: #da2055 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <strong style="margin-top: -3px">!</strong>
                </div>
                <div
                  v-if="employee.not_logged_in && employee.notLoggedInMinutes <= 45"
                  style="
                    text-align: center;
                    display: inline-block;
                    height: 100%;
                    width: max-content;
                    padding-left: 15px;
                    letter-spacing: 1px;
                    font-size: 19px;
                    overflow-y: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 0;
                    color: #da2055;
                    position: absolute;
                  "
                  v-bind:style="[GetTimeBarText(), { left: GetRedBarTextLeft(employee) }]"
                >
                  <strong
                    style="
                      text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff;
                    "
                    >Zu spät
                    <!-- &bull; {{ employee.notLoggedInMinutes}} <small>min</small>-->
                  </strong>
                </div>
                <div
                  v-if="employee.not_logged_out"
                  style="
                    text-align: center;
                    display: inline-block;
                    height: 100%;
                    width: max-content;
                    padding-left: 15px;
                    letter-spacing: 1px;
                    font-size: 19px;
                    overflow-y: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 0;
                    color: #da2055;
                    position: absolute;
                  "
                  v-bind:style="[GetTimeBarText(), { left: GetRedBarTextLeft(employee) }]"
                >
                  <strong>Mehrstunden &bull; {{ employee.overtimeMinutes }} <small>min</small></strong>
                </div>

                <div
                  v-if="false && !employee.not_logged_in && employee.additionalPauseMandatory && !loadingPauses"
                  style="
                    text-align: center;
                    display: inline-block;
                    height: 100%;
                    width: max-content;
                    padding-left: 15px;
                    letter-spacing: 1px;
                    font-size: 19px;
                    overflow-y: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 0;
                    color: #da2055;
                    position: absolute;
                  "
                  v-bind:style="[GetTimeBarText(), { left: GetRedBarTextLeft(employee) }]"
                >
                  <strong>Geh auf Mittagspause</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!MAINTENANCE" class="linesMiddleHourDiv" v-bind:style="[{ 'margin-left': line.leftHalf + 'px', width: oneHourWidth + 'px' }, GetGridTop()]" v-for="line in verticalLinesHours" :key="'divMiddleHour' + line.id">
      {{ line.hourCode }}
    </div>

    <div
      v-if="!MAINTENANCE"
      class="linesMiddle"
      style="z-index: 4"
      v-bind:style="[
        {
          'margin-left': line.left + 'px',
          display: showHourlyDivisions ? '' : 'none',
          'z-index': showHourlyDivisionsOverBars ? '4 !important' : ''
        },
        GetGridTop()
      ]"
      v-for="line in verticalLines"
      :key="line.id"
    ></div>
    <div v-if="!MAINTENANCE" class="lineLeft" v-bind:style="[GetGridTop()]"></div>
    <div v-if="!MAINTENANCE" class="lineRight" v-bind:style="[GetGridTop()]"></div>

    <div v-if="!MAINTENANCE" class="timeLine" v-bind:style="{ left: showPauses ? '105px' : '305px' }"></div>
    <div v-if="!MAINTENANCE" class="timeDiv" v-bind:style="{ left: showPauses ? '107px' : '307px' }">{{ clockHH }}<span class="timeDoublePoint" v-bind:style="{ opacity: timeDoublePointOpacity }">:</span>{{ clockMM }}</div>

    <div class="pause-container" v-if="!MAINTENANCE && currentPauses.length > 0" v-bind:style="[GetGridTop()]">
      <div class="header">Pause</div>
      <div class="pauses">
        <div class="employee-pause" v-for="pause in currentPauses" :key="pause.employee.id">
          <div class="circle">
            <div class="cover-left" v-bind:style="GetPauseCover(1 - (timestamp + timeZoneShift - pause.pause.start) / pause.pause.duration, 'left', pause.pause)"></div>
            <div class="cover-mid"></div>
            <div class="cover-right" v-bind:style="GetPauseCover(1 - (timestamp + timeZoneShift - pause.pause.start) / pause.pause.duration, 'right', pause.pause)"></div>
          </div>
          <div class="timer fixed"></div>
          <div class="timer" v-bind:style="{ opacity: opacityTimer }">
            {{ GetPauseTimerText(pause) }}
          </div>
          <div
            class="picture"
            v-bind:style="{
              opacity: opacityPicture,
              background: 'url(' + GetProfilePicture(pause.employee) + ')',
              'background-size': '90px 90px',
              'background-repeat': 'no-repeat'
            }"
          ></div>
          <div
            class="icon fas"
            v-bind:class="{
              'fa-coffee': pause.pause.duration < 60 * 30,
              'fa-utensils': pause.pause.duration >= 60 * 30
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "Pausenplan",
  props: {},
  data: function() {
    return {
      retries: 1,
      MAINTENANCE: false,

      logging: true,
      pausenplanId: "",
      pausenplanToken: "",
      publicPausenplanId: "",
      publicPausenplanToken: "",
      storeId: 33,
      pausenplanIdentifier: "",

      showIdentify: false,
      showIdentifyTime: 0,

      timestamp: 0,
      timestampOffset: 0,
      positions: [],
      positionsOriginal: [],
      rotas: [],
      hiddenRotas: [],
      shifts: [],
      pauses: [],
      employees: [],

      customPositions: [],

      employeeMap: {},

      timeDoublePointOpacity: 1,
      alertIconOpacity: 1,

      timeframe: 17,
      lowerBound: 0,
      upperBound: 0,

      verticalLines: [],
      verticalLinesHours: [],

      timelinesWidth: 0,
      secondPerPixel: 0,
      oneHourWidth: 0,

      showRestingArea: true,
      showFinished: true,
      hideFinishedWhichAreOlderThanHours: 5,
      showFuture: 0,

      headerPadding: 100,

      randomFirstnames: ["Mia", "Emilia", "Sophia", "Hanna", "Emma", "Lina", "Mila", "Lea", "Marie", "Ella", "Louisa", "Klara", "Amelie", "Frida", "Emilie", "Ida", "Leonie", "Lya", "Matilda", "Sofie", "Anna", "Lena", "Charlotte", "Johanna", "Leni"],
      randomLastnames: ["Schmidt", "Schneider", "Fischer", "Weber", "Meyer", "Wagner", "Becker", "Schulz", "Hoffmann", "Koch", "Bauer", "Richter", "Klein", "Wolf", "Neumann", "Schwarz", "Zimmermann", "Braun", "Hofmann", "Hartmann", "Lange", "Schmitt", "Werner", "Schmitz", "Krause"],

      showPauses: false,
      rowHeightDefault: 50,

      rowHeightMax: 75,
      rowHeightMin: 20,
      rowHeightCurrent: 50,
      timeBarMargin: 8,
      timeBarPadding: 26,
      employeePadding: 5,
      currentPauses: [],
      opacityAnimation: 0,

      timeZoneShift: 0, //(new Date().getTimezoneOffset() * 60)

      actualShiftEndedOpacity: 0.6,
      actualShiftNotStartedOpacity: 0.6,

      showHourlyDivisions: true,
      showHourlyDivisionsOverBars: false,

      usePositions: true,
      showDefaultPause: false,
      countryId: 1,

      spotActualShiftColorEnabled: true,
      spotPlanShiftColorEnabled: true,

      customPlanShiftColorEnabled: false,
      customPlanShiftColor: "#202265",

      customPlannedPauseColorEnabled: false,
      customPlannedPauseColor: "#30c321",

      defaultLegalPauseColor: "#bababa",
      customLegalPauseColor: "#1030c3",
      customLegalPauseColorEnabled: false,
      legalPauseErrorColor: "#da2055",

      loadingPauses: true,
      pauseCallbackReceived: false,

      customActualColor: "#2ac3b5",

      optionsCallDidRun: false,
      positionsCallDidRun: false
    };
  },
  created: function() {},
  computed: {
    /*
        content: function () {
            let pages = [];
        },*/
    clockHH: function() {
      let date = moment.unix(this.timestamp);
      return date.format("HH");
    },
    clockMM: function() {
      let date = moment.unix(this.timestamp);
      return date.format("mm");
    },
    opacityTimer() {
      let state = parseInt((this.opacityAnimation / 1000) % 12);
      let transition = (this.opacityAnimation - state * 1000) / 1000;
      if (state === 1) return transition;
      if (state > 1 && state < 4) return 1;
      if (state === 5) return 1 - transition;
      return 0;
    },
    opacityPicture() {
      let state = parseInt((this.opacityAnimation / 1000) % 12);
      let transition = (this.opacityAnimation - state * 1000) / 1000;
      if (state === 7) return transition;
      if (state > 7 && state < 10) return 1;
      if (state === 11) return 1 - transition;
      return 0;
    }
  },
  mounted: function() {
    let view = this;

    /* Get ID & Token for Pausenplan Authentication */
    var url = new URL(window.location.href);
    this.pausenplanId = url.searchParams.get("id");
    this.pausenplanToken = url.searchParams.get("token");
    if (this.logging) console.log("Pausenplan ID: " + this.pausenplanId);
    if (this.logging) console.log("Pausenplan Token: " + this.pausenplanToken);

    this.publicPausenplanId = url.searchParams.get("publicId");
    this.publicPausenplanToken = url.searchParams.get("publicToken");
    if (this.logging) console.log("Public Pausenplan ID: " + this.publicPausenplanId);
    if (this.logging) console.log("Public Pausenplan Token: " + this.publicPausenplanToken);

    /* ******************************************** */
    window.Echo.channel("maintenance").listen("MaintenanceEvent", (e) => {
      window.location.reload(true);
    });

    if (this.pausenplanId != null && this.pausenplanToken != null) {
      window.Echo.channel("pausenplan." + this.pausenplanId + "." + this.pausenplanToken).listen("PausenplanNotificationEvent", (e) => {
        if (e.data.model == "option") {
          this.setPausenplanOptions(e.data.data[0]);
        } else if (e.data.model == "identify") {
          this.showIdentify = true;
          this.showIdentifyTime = 15000;
        } else {
          //this.getTimestamp();
          this.getPositions();
        }
      });
    }
    if (this.publicPausenplanId != null && this.publicPausenplanToken != null) {
      window.Echo.channel("pausenplan." + this.publicPausenplanId + "." + this.publicPausenplanToken).listen("PausenplanNotificationEvent", (e) => {
        if (e.data.model == "option") {
          this.setPausenplanOptions(e.data.data[0]);
        } else if (e.data.model == "identify") {
          this.showIdentify = true;
          this.showIdentifyTime = 15000;
        } else {
          //this.getTimestamp();
          this.getPositions();
        }
      });
    }

    // Update the clock every second, and let the double points blink
    let secondsTimeout = setTimeout(function tickSeconds() {
      //view.timestamp = view.timestamp + 1;
      /*
            let A = new Date();
            view.timestamp = A.getTime() / 1000;
            */
      view.timestamp = moment().unix();

      if (view.timeDoublePointOpacity == 1) {
        view.timeDoublePointOpacity = 0.5;
        view.alertIconOpacity = 0.2;
      } else {
        view.timeDoublePointOpacity = 1;
        view.alertIconOpacity = 1;
      }
      view.currentPauses = []; //view.currentPauses.filter(pauseCurrent => !(pauseCurrent.pause.start < view.timestamp && (pauseCurrent.pause.start + (pauseCurrent.pause.duration)) > view.timestamp));

      view.positions.forEach((p) => {
        if (p.active && p.employees)
          p.employees.forEach((employee) => {
            var pause = employee.pauses.find((pauseEmployee) => pauseEmployee.start < view.timestamp + view.timeZoneShift && pauseEmployee.start + pauseEmployee.duration > view.timestamp + view.timeZoneShift);
            if (pause) {
              if (!view.currentPauses.find((pauseCurrent) => pauseCurrent.pause === pause)) view.currentPauses.push({ employee: employee, pause: pause });
            }
          });
      });
      view.currentPauses.sort(function sort(a, b) {
        return view.timestamp - (b.pause.start + b.pause.duration) > view.timestamp - (a.pause.start + a.pause.duration) ? 1 : -1;
      });
      let topOffset = 100;
      let pauseHeader = 40;
      let bottomPadding = 40;
      let pauseHeight = 150;
      let maxPauses = (window.innerHeight - topOffset - pauseHeader - bottomPadding) / pauseHeight;
      view.currentPauses.slice(0, Math.floor(maxPauses));
      view.showIdentifyTime = view.showIdentifyTime - 1000;
      if (view.showIdentifyTime <= 0) {
        view.showIdentify = false;
        view.showIdentifyTime = 0;
      }
      secondsTimeout = setTimeout(tickSeconds, 1000);
    }, 0);

    let alertIconOpacityTimeout = setTimeout(function tickAlert() {
      if (view.alertIconOpacity == 1) {
        view.alertIconOpacity = 0.2;
      } else {
        view.alertIconOpacity = 1;
      }
      alertIconOpacityTimeout = setTimeout(tickAlert, 3000);
    }, 0);

    //Resize the Timeline every 30 seconds
    let timelinesTimeout = setTimeout(function tickTimeout() {
      view.resizeTimelines();
      timelinesTimeout = setTimeout(tickTimeout, 30000);
    }, 0);

    let whateverTimeout = setTimeout(function tickWhatever() {
      for (let i in view.rotas) {
        view.rotas[i].taken = false;
      }
      for (let i in view.shifts) {
        view.shifts[i].taken = false;
      }
      for (let i in view.positions) {
        let position = view.positions[i];
        position.employees = view.getEmployeesForPosition(position);
      }
      view.loadingPauses = !view.pauseCallbackReceived;
      whateverTimeout = setTimeout(tickWhatever, 25000);
    }, 0);

    this.getTimestamp();
    this.getPausenplanOptions();
    this.getPositions();
    this.UpdateOpacityAnimation();
  },
  methods: {
    EmployeeShiftEnded(employee) {
      let view = this;
      let stillRunning = employee.shifts.find((s) => s.show && s.start && s.start < view.timestamp && s.end == null);
      if (stillRunning) return false;
      let find = employee.shifts.find((s) => s.show && s.end && s.end < view.timestamp);
      if (!find) find = employee.rotas.find((s) => s.show && s.end && s.end < view.timestamp);
      return find;
    },
    EmployeeActualShiftNotStarted(employee) {
      let view = this;
      return employee.shifts.find((s) => s.show && s.shift_start && s.shift_start > view.timestamp);
    },
    GetShiftOvertime(employee, shift) {
      let view = this;
      let rota = employee.rotas.find((r) => r.show && r.rota_start < view.timestamp);
      if (rota && shift.end && shift.end > rota.end) {
        return {
          right: 0 + "px",
          width: (shift.shift_end - rota.rota_end) * view.secondPerPixel + "px"
        };
      }
      return { display: "none" };
    },
    UpdateOpacityAnimation() {
      let view = this;
      view.opacityAnimation = new Date().getTime() / 1000;
      requestAnimationFrame(function() {
        view.UpdateOpacityAnimation();
      });
    },
    GetPauseCover(pauseLeft, side, pause) {
      if (side === "left") {
        if (pauseLeft < 0.5)
          return {
            transform: "rotate(" + (-360 + 360 * (0.5 - pauseLeft)) + "deg) translate(-50%, 0%)"
          };
        else return { transform: "translate(-50%, 0%)" };
      } else {
        return {
          display: pauseLeft < 0.5 ? "none" : "",
          transform: "rotate(" + 360 * (1 - pauseLeft) + "deg) translate(50%, 0%)"
        };
      }
    },
    hexToHSLObject(H) {
      let r = 0,
        g = 0,
        b = 0;
      if (H.length === 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
      } else if (H.length === 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
      }
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

      if (delta === 0) h = 0;
      else if (cmax === r) h = ((g - b) / delta) % 6;
      else if (cmax === g) h = (b - r) / delta + 2;
      else h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0) h += 360;

      l = (cmax + cmin) / 2;
      s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);

      return { h: h, s: s, l: l };
    },
    GetContrastingColor(hex, lightcolor = "white", darkcolor = "#404040", contrastLimit = 70) {
      if (!hex) return darkcolor;
      let hsl = this.hexToHSLObject(hex);
      if (hsl.l < contrastLimit) return lightcolor;
      else return darkcolor;
    },
    GetPositionName(position) {
      if (position.name == null) return "";
      let reductionRatio = 1 / 3 + this.rowHeightCurrent / (50 * 1.5);
      let employees = position.employees.filter((e) => e.show);
      let letterWidth = 7;
      let positionHeight = employees.length * this.rowHeightCurrent * reductionRatio;
      if (position.name.length * letterWidth > positionHeight - 5) {
        return position.name.substr(0, parseInt((positionHeight - 5) / letterWidth) + 1);
      }
      return position.name;
    },

    GetGridTop() {
      return { transform: "translateY(" + (this.headerPadding - 80) + "px)" };
    },
    resizeTimelines: function() {
      let view = this;
      view.timelinesWidth = document.getElementById("content").offsetWidth - (this.showPauses ? 540 : 340);
      view.secondPerPixel = view.timelinesWidth / (view.timeframe * 3600);
      view.oneHourWidth = view.secondPerPixel * 3600;
      view.calculateTimeLines();
      let employees = [];
      view.positions.filter((p) => p.employees).forEach((p) => p.employees.forEach((e) => employees.push(e)));
      employees = employees.filter((e) => e.show);
      let employeeCount = 0;
      for (let i in view.positions) {
        if (view.positions[i].active) {
          if (view.positions[i].employees != undefined) {
            let tmpEmployees = view.positions[i].employees.filter((e) => e.show);
            employeeCount = employeeCount + tmpEmployees.length;
          }
        }
      }
      if (employeeCount == 0) employeeCount = 1;
      view.rowHeightCurrent = (window.innerHeight - 130 - 30) / employeeCount;
      if (view.rowHeightCurrent > view.rowHeightMax) view.rowHeightCurrent = view.rowHeightMax;
      if (view.rowHeightCurrent < view.rowHeightMin) view.rowHeightCurrent = view.rowHeightMin;
    },

    showPosition: function(position) {
      if (!position.active) return false;
      let employees = position.employees;
      for (let i in employees) {
        let employee = employees[i];
        if (employee.show) return true;
      }
      return false;
    },

    getEmployeesForPosition: function(position) {
      let view = this;
      let employees = {};

      let date = moment.unix(this.timestamp);

      for (let i in view.employees) {
        employees[view.employees[i].id] = {
          employee_id: view.employees[i].id,
          extern: false,
          show: false,
          birthday: false,
          underage: false,
          starting: 999999999999999999999,
          rotas: [],
          shifts: [],
          pauses: [],
          overtimes: []
        };

        if (view.employees[i].dob != null) {
          try {
            let dob = moment(view.employees[i].dob, "YYYY-MM-DD");

            let tmpX = moment.duration(date.diff(dob));
            let tmpDiff = tmpX.asYears();
            if (tmpDiff < 18) {
              employees[view.employees[i].id].underage = true;
            }

            if (date.format("MM-DD") == dob.format("MM-DD")) {
              employees[view.employees[i].id].birthday = true;
            }
          } catch (e) {
            console.log(e);
          }
        }
      }

      // Iterate all rotas (planshifts) and map them to the employee within the correct position
      for (let i in view.rotas) {
        let rota = view.rotas[i];
        rota.doNotShow = false;
        if (!rota.extern && rota.externalStoreId) {
          rota.doNotShow = true;
        }
        if (employees[rota.employee_id]) {
          if (position.md_id == rota.spot_id || (position.md_id == "00000000-0000-0000-0000-000000000000" && !rota.taken && !rota.extern) || (position.md_id == "10000000-0000-0000-0000-000000000000" && !rota.taken && rota.extern)) {
            view.rotas[i].taken = true;
            if (rota.extern) {
              employees[rota.employee_id].extern = true;
            }
            let rota_duration = rota.end - rota.start;
            let rota_left = (rota.start - view.lowerBound) * view.secondPerPixel;
            let rota_width = rota_duration * view.secondPerPixel;

            let legalPauseRest11_left = (rota.start - (11 * 3600) - view.lowerBound) * view.secondPerPixel;
            let legalPauseRest11_width = rota_left - legalPauseRest11_left;
            let legalPauseRest11_show = true;

            if(rota.start - (11*3600) - view.lowerBound < (view.timeframe *3600) / 2){
              legalPauseRest11_left = 0;
              legalPauseRest11_width = 0;
              legalPauseRest11_show = false;
            }
            if(rota.start - (11*3600) - view.lowerBound > (view.timeframe *3600) / 2 + 3600*3){
              legalPauseRest11_left = 0;
              legalPauseRest11_width = 0;
              legalPauseRest11_show = false;
            }

            let pauseLegal_left = 0;
            let pauseLegal_width = 0;

            if (employees[rota.employee_id].underage) {
              if (rota_duration > 4.5 * 3600) {
                let tmp = rota.end - (6 * 3600 + 30 * 60);
                pauseLegal_left = Math.max(rota_left, (tmp - view.lowerBound) * view.secondPerPixel);
                pauseLegal_width = Math.min(rota_width, (rota.start + (6 * 3600 + 30 * 60) - tmp) * view.secondPerPixel);
              }
            } else {
              if (rota_duration > 6 * 3600) {
                let tmp = rota.end - (6 * 3600 + 30 * 60);
                pauseLegal_left = Math.max(rota_left, (tmp - view.lowerBound) * view.secondPerPixel);
                pauseLegal_width = Math.min(rota_width, (rota.start + (6 * 3600 + 30 * 60) - tmp) * view.secondPerPixel);
              }
            }

            if(view.showDefaultPause){
              let tmp = rota.end - rota.start;
              if (employees[rota.employee_id].underage) {
                if(view.countryId == 1){
                  //Austria
                  if (rota_duration > 4.5 * 3600) {
                    rota.planPauseStart = rota.start + (tmp / 2);
                    rota.planPauseDuration = 30 * 60;
                  }
                }else if(view.countryId == 2){
                  //Germany
                  if (rota_duration > 6 * 3600) {
                    rota.planPauseStart = rota.start + (tmp / 2);
                    rota.planPauseDuration = 60 * 60;
                  }else if (rota_duration > 4.5 * 3600) {
                    rota.planPauseStart = rota.start + (tmp / 2);
                    rota.planPauseDuration = 30 * 60;
                  }
                }
              }else{
                if(view.countryId == 1){
                  //Austria
                  if (rota_duration > 6 * 3600) {
                    rota.planPauseStart = rota.start + (tmp / 2);
                    rota.planPauseDuration = 30 * 60;
                  }
                }else if(view.countryId == 2){
                  //Germany
                  if (rota_duration > 9 * 3600) {
                    rota.planPauseStart = rota.start + (tmp / 2);
                    rota.planPauseDuration = 45 * 60;
                  }else if (rota_duration > 6 * 3600) {
                    rota.planPauseStart = rota.start + (tmp / 2);
                    rota.planPauseDuration = 30 * 60;
                  }
                }
              }
            }

            let obj = {
              show: true,
              id: rota.id,
              rota_start: rota.start,
              rota_end: rota.end,
              end: rota.end,

              rota_left: Math.max(0, rota_left),
              rota_width: Math.max(0, rota_width + Math.min(0, rota_left)),

              legalPauseRest11_left: Math.max(0, legalPauseRest11_left),
              legalPauseRest11_width: legalPauseRest11_width,
              legalPauseRest11_show: legalPauseRest11_show,

              pauseLegal_left: Math.max(0, pauseLegal_left),
              pauseLegal_width: Math.max(0, pauseLegal_width + Math.min(0, pauseLegal_left)),
              planPauseStart: rota.planPauseStart,
              planPauseDuration: rota.planPauseDuration
            };
            employees[rota.employee_id].rotas.push(obj);
          }
        }
      }
      for (let i in employees) {
        let employee = employees[i];
        for (let j in employee.rotas) {
          // This should be only 1-3 rotas per employee regarding the selected timeframe. So very fast iterations
          let rota = employee.rotas[j];
          if (view.showFinished) {
            if (rota.rota_end < view.lowerBound + (view.timeframe / 2 - view.hideFinishedWhichAreOlderThanHours) * 3600) {
              // Hide rotas which are below the lower bound on the left side
              rota.show = false;
            }
            if (view.showFuture == 0) {
              // Show all future shifts until the upper bound is reached
              if (rota.rota_start > view.upperBound - 15 * 60) {
                rota.show = false;
              }
              if (rota.rota_end > view.lowerBound + (view.timeframe / 2 - view.hideFinishedWhichAreOlderThanHours) * 3600 && rota.rota_start < view.upperBound - 15 * 60) {
                employee.show = true;
              }
            } else {
              // Show future shifts which are within the showFuture variable
              if (rota.rota_start > view.timestamp + view.showFuture * 3600) {
                rota.show = false;
              }
              if (rota.rota_end > view.lowerBound + (view.timeframe / 2 - view.hideFinishedWhichAreOlderThanHours) * 3600 && rota.rota_start < view.timestamp + view.showFuture * 3600) {
                employee.show = true;
              }
            }
          } else {
            if (rota.rota_end < view.timestamp) {
              // Hide rotas which are below the current timestamp
              rota.show = false;
            }
            if (view.showFuture == 0) {
              if (rota.rota_start > view.upperBound - 15 * 60) {
                rota.show = false;
              }
              if (rota.rota_end > view.timestamp && rota.rota_start < view.upperBound - 15 * 60) {
                employee.show = true;
              }
            } else {
              if (rota.rota_start > view.timestamp + view.showFuture * 3600) {
                rota.show = false;
              }
              if (rota.rota_end > view.timestamp && rota.rota_start < view.timestamp + view.showFuture * 3600) {
                employee.show = true;
              }
            }
          }
        }
      }

      // Iterate all shifts (actualshifts) and map them to the employee within all positions (cause an employee can only be present one time anyways)
      // If someone has a very large timeframe selected, AND in addition a employee has to work in 2 different positions within this timeframe, then he will be seen twice
      for (let i in view.shifts) {
        let shift = view.shifts[i];
        if (shift.taken) continue;
        if (shift.extern && position.md_id == "00000000-0000-0000-0000-000000000000") continue;
        if (employees[shift.employee_id]) {
          if (position.md_id != "00000000-0000-0000-0000-000000000000") {
            if (employees[shift.employee_id].rotas.length == 0) {
              continue;
            }
            let isVisible = false;
            for (let j in employees[shift.employee_id].rotas) {
              if (employees[shift.employee_id].rotas[j].show && !employees[shift.employee_id].rotas[j].doNotShow) {
                isVisible = true;
              }
            }
            if (!isVisible) continue;
          }

          let end = view.timestamp;
          if (shift.end) {
            end = shift.end;
            if (end < view.lowerBound + (view.timeframe / 2 - view.hideFinishedWhichAreOlderThanHours) * 3600) continue; // Hide shifts which are (timeframe/2 - 3h) old and only a little bit is visible any more
          } else {
            if (shift.start < view.timestamp - 14 * 3600) {
              continue;
            }
          }
          let shift_duration = end - shift.start;
          let shift_left = (shift.start - view.lowerBound) * view.secondPerPixel;
          let shift_width = shift_duration * view.secondPerPixel;

          let tmp = moment.unix(shift.start);
          let tmp2 = moment.unix(shift.end);

          let obj = {
            show: true,
            id: shift.id,
            shift_start: shift.start,
            shift_end: end,
            start: shift.start,
            end: shift.end,
            start_hr: tmp.format("HH:mm"),
            end_hr: tmp2.format("HH:mm"),

            shift_left: Math.max(0, shift_left),
            shift_width: Math.max(0, shift_width + Math.min(0, shift_left)),
            id_rota: shift.id_rota
          };
          shift.taken = true;
          employees[shift.employee_id].shifts.push(obj);
        }
      }
      for (let i in employees) {
        let employee = employees[i];
        let shiftIds = [];
        for (let j in employee.shifts) {
          // This should be only 1-3 shifts per employee regarding the selected timeframe. So very fast iterations
          let shift = employee.shifts[j];
          shiftIds.push(shift.id);
          if (shift.shift_start > view.timestamp) {
            shift.show = false;
          }
          if (view.showFinished) {
            if (shift.shift_end < view.lowerBound) {
              // Hide shifts which are below the lower bound on the left side
              shift.show = false;
            }
            if (shift.shift_end > view.lowerBound && shift.shift_start < view.timestamp) {
              employee.show = true;
            }
          } else {
            if (shift.shift_end < view.timestamp) {
              // Hide shifts which are below the current timestamp
              shift.show = false;
            }
            if (shift.shift_end > view.timestamp && shift.shift_start < view.timestamp) {
              employee.show = true;
            }
          }
        }
        if (shiftIds.length > 0) {
          for (let k in view.pauses) {
            let pause = view.pauses[k];
            if (shiftIds.includes(pause.id_shift)) {
              let pause_duration = pause.end - pause.start;
              let pause_left = (pause.start - view.lowerBound) * view.secondPerPixel;
              let pause_width = pause_duration * view.secondPerPixel;
              let obj = {
                show: true,
                id: pause.id,
                pause_start: pause.start,
                pause_end: pause.end,
                start: pause.start,
                end: pause.end,
                duration: pause_duration,

                pause_left: Math.max(0, pause_left),
                pause_width: Math.max(0, pause_width + Math.min(0, pause_left))
              };
              employee.pauses.push(obj);
            }
          }
        }
      }

      for (let i in employees) {
        let employee = employees[i];
        employee.overtimes = [];
        if (employee.show) {
          employee.additionalPauseMandatory = false;
          for (let j in employee.rotas) {
            let rota = employee.rotas[j];

            //Check if employee has logged in
            if (rota.rota_start < view.timestamp && rota.rota_start > view.timestamp - 12 * 3600) {
              employee.not_logged_in = true;
              employee.notLoggedInMinutes = Math.floor((view.timestamp - rota.rota_start) / 60);
              for (let k in employee.shifts) {
                let shift = employee.shifts[k];
                if (shift.shift_start > view.timestamp - 12 * 3600) {
                  employee.not_logged_in = false;
                }
              }
            }

            if (rota.rota_start < view.timestamp && rota.rota_end > view.timestamp) {
              if (rota.rota_end - rota.rota_start > 6 * 3600) {
                let pauseDuration = 0;
                let legalMinimum = rota.rota_end - (6 * 3600 + 30 * 60);
                let legalMaximum = rota.rota_start + (6 * 3600 + 30 * 60);
                if (legalMaximum - 120 * 60 < view.timestamp) {
                  if (legalMaximum /*- 30*60*/ > view.timestamp) {
                    // If the legal pause timeframe is already over, then no need to show the warning. The employee missed the pause anyways, so hide the warning, the manager has to solve it afterwards.
                    for (let k in employee.pauses) {
                      let pause = employee.pauses[k];
                      //let start = Math.max(legalMinimum,pause.start);
                      //let end = Math.min(legalMaximum,pause.end);
                      let start = pause.start;
                      let end = pause.end;
                      let duration = Math.max(0, end - start);
                      duration >= 900 && duration < 1800 ? (duration = 900) : duration >= 1800 ? (duration = 1800) : (duration = 0);
                      pauseDuration += duration;
                    }
                    if (pauseDuration < 1800) {
                      employee.additionalPauseMandatory = true;
                    }
                  }
                }
              }
            }

            //check if employee has logged out
            if (rota.rota_start < view.timestamp && rota.rota_start > view.timestamp - 12 * 3600 && rota.rota_end < view.timestamp) {
              employee.not_logged_out = false;
              for (let k in employee.shifts) {
                let shift = employee.shifts[k];
                if (shift.id_rota == rota.id && shift.end == null) {
                  employee.not_logged_out = true;
                  employee.overtimeMinutes = Math.floor((view.timestamp - rota.rota_end) / 60);

                  let prev_width = shift.shift_width;

                  let shift_duration = rota.end - shift.start;
                  let shift_left = (shift.start - view.lowerBound) * view.secondPerPixel;
                  let shift_width = shift_duration * view.secondPerPixel;
                  shift.shift_width = Math.max(0, shift_width + Math.min(0, shift_left));

                  let obj = {
                    overtime_left: shift_left + shift_width,
                    overtime_width: prev_width - shift.shift_width
                  };
                  employee.overtimes.push(obj);
                }
              }
            }
          }
        }
      }

      //The next iterations goes through all rotas/shifts , and sets the "starting" timestamp on the employee, in order to order the employees by that value
      for (let i in employees) {
        let employee = employees[i];
        for (let j in employee.rotas) {
          let rota = employee.rotas[j];
          if (rota.show) {
            employee.starting = Math.min(employee.starting, rota.rota_start);
          }
        }
        for (let j in employee.shifts) {
          let shift = employee.shifts[j];
          if (shift.show) {
            employee.starting = Math.min(employee.starting, shift.shift_start);
          }
        }
      }

      // The next iteration pushes the employees from the currently created map into an array, and then sorts the employees by the previously set starting value
      let employeesArray = [];
      for (let i in employees) {
        let employee = employees[i];
        employeesArray.push(employee);
      }
      employeesArray.sort(function sort(a, b) {
        //if(a.show == false || b.show == false)return 0;
        return b.starting < a.starting ? 1 : b.starting > a.starting ? -1 : 0;
      });

      return employeesArray;
    },

    calculateTimeLines: function() {
      let view = this;
      view.verticalLines = [];
      view.verticalLinesHours = [];
      view.lowerBound = view.timestamp - (view.timeframe * 3600) / 2;
      view.upperBound = view.timestamp + (view.timeframe * 3600) / 2;

      let tmp = moment.unix(view.lowerBound);
      let timestamp = view.lowerBound + (60 - tmp.minutes()) * 60 - tmp.seconds();

      let lineStart = timestamp;
      let id = 0;
      while (lineStart < view.upperBound) {
        let date = moment.unix(lineStart);
        let obj = {
          id: id,
          moment: moment.unix(timestamp),
          left: (lineStart - view.lowerBound) * view.secondPerPixel,
          leftHalf: (lineStart - view.lowerBound) * view.secondPerPixel - view.oneHourWidth / 2,
          hourCode: date.format("HH") + ":00"
        };
        if (lineStart > view.timestamp - 4000 && lineStart < view.timestamp + 4000) {
          obj.hourCode = "";
        }
        view.verticalLines.push(obj);
        view.verticalLinesHours.push(obj);
        lineStart = lineStart + 3600;
        id++;
      }
    },
    GetPauseTimeRemainingText(pause) {
      if (!pause) return "";
      let timeRemaining = pause.duration - (this.timestamp + this.timeZoneShift - pause.start);
      let minutes = parseInt(timeRemaining / 60);
      let seconds = parseInt(timeRemaining % 60);
      if (seconds < 10) seconds = "0" + seconds;
      return "-" + minutes + ":" + seconds;
    },
    GetEmployeeFirstName: function(employee) {
      //return this.randomFirstnames[Math.floor(Math.random() * 25)];

      return this.employeeMap[employee.employee_id].firstname ? this.employeeMap[employee.employee_id].firstname : "";
    },
    GetEmployeeLastName: function(employee) {
      //return this.randomLastnames[Math.floor(Math.random() * 25)];
      return this.employeeMap[employee.employee_id].lastname ? this.employeeMap[employee.employee_id].lastname : "";
    },
    GetProfilePicture: function(employee) {
      //return "https://fileserver1.meindienstplan.de/api/file?id=d55fd03c-9999-4a48-8515-906585d0bc0e&accessToken=ALPW6DGLB92U2U0S";
      if (!this.employeeMap[employee.employee_id].profile_picture_storageServerId) return "/images/avatar.png";
      return "https://fileserver1.meindienstplan.de/api/file?id=" + this.employeeMap[employee.employee_id].profile_picture_storageServerId + "&accessToken=" + this.employeeMap[employee.employee_id].profile_picture_storageServerAccessToken;
    },
    GetPauseTimerText(pauseContainer) {
      let timeString = this.GetPauseTimeRemainingText(this.GetEmployeePauseCurrent(pauseContainer.employee));
      if (timeString.split(":")[0] === "-0") return timeString.split(":")[1];
      return timeString.split(":")[0].replace("-", "");
    },
    GetExternMarkerStyle() {
      let reductionRatio = 1 / 3 + this.rowHeightCurrent / (this.rowHeightDefault * 1.5);
      let size = this.rowHeightCurrent - this.employeePadding * 2 + -8 + "px";
      if (this.rowHeightCurrent >= 35) {
        return {
          height: 20 * reductionRatio + "px",
          width: 20 * reductionRatio + "px",
          top: 17 * reductionRatio + "px",
          "margin-left": "-25px",
          border: "2px solid white"
        };
      } else {
        return {
          height: 20 * reductionRatio + "px",
          width: 20 * reductionRatio + "px",
          top: 17 * reductionRatio + "px",
          "margin-left": "-25px",
          border: "2px solid white"
        };
      }
    },
    GetExternMarkerIconStyle() {
      let reductionRatio = 1 / 3 + this.rowHeightCurrent / (this.rowHeightDefault * 1.5);

      let size = this.rowHeightCurrent - this.employeePadding * 2 - 10 + "px";
      let lH = this.rowHeightCurrent - this.employeePadding * 2 - 16 + "px";

      if (this.rowHeightCurrent >= 35) {
        return {
          height: 17 * reductionRatio + "px",
          "line-height": 17 * reductionRatio + "px",
          width: 17 * reductionRatio + "px",
          "font-size": 12 * reductionRatio + "px"
        };
      } else {
        return {
          height: 17 * reductionRatio + "px",
          "line-height": 17 * reductionRatio + "px",
          width: 17 * reductionRatio + "px",
          "font-size": 8 * reductionRatio + "px"
        };
      }
    },
    GetEmployeePictureStyle() {
      let size = this.rowHeightCurrent - this.employeePadding * 2 + "px";
      if (this.rowHeightCurrent >= 25) return { height: size, width: size };
      return { display: "none" };
    },
    GetEmployeeNameStyle(type) {
      if (this.rowHeightCurrent < 35)
        return {
          position: "relative",
          margin: "initial",
          left: 0,
          "margin-right": "5px"
        };
      let reductionRatio = 1 / 3 + this.rowHeightCurrent / (this.rowHeightDefault * 1.5);
      let style = { "font-size": 14 * reductionRatio + "px" };
      if (type === "first") style["margin-top"] = 6 * reductionRatio + "px";
      if (type === "last") style["margin-bottom"] = 6 * reductionRatio + "px";
      return style;
    },
    GetIconSize(inverse) {
      let reductionRatio = 1 / 3 + this.rowHeightCurrent / (this.rowHeightDefault * 1.5);
      if (inverse) reductionRatio = 1 / reductionRatio;
      return { transform: "scale(" + reductionRatio + ")" };
    },
    GetEmployeePauseCurrent(employee) {
      let view = this;
      let pause = employee.pauses.find((p) => p.start < view.timestamp + view.timeZoneShift && p.start + p.duration > view.timestamp + view.timeZoneShift);
      /*if(pause) {
                view.currentPauses = view.currentPauses.filter(p => !(p.pause.start < view.timestamp && (p.pause.start + (p.pause.duration)) > view.timestamp));
                if(!view.currentPauses.find(p => p.pause === p)) view.currentPauses.push({employee: employee, pause: pause});
            }*/
      return pause;
    },
    GetTimeBarHeight(type) {
      let reductionRatio = this.rowHeightCurrent / this.rowHeightDefault;
      //            if(type === 'bar') return {height: (this.rowHeightCurrent) + 'px'}
      let margin = Math.ceil(this.timeBarMargin * reductionRatio);
      if (type === "bar") return { height: this.rowHeightCurrent - margin + "px" };
      let padding = Math.ceil(this.timeBarPadding * reductionRatio);
      if (type === "outer") return { height: this.rowHeightCurrent - 0 + "px" };
      if (type === "inner") return { height: this.rowHeightCurrent - padding + "px" };
    },
    GetTimeBarHeightValue(type) {
      let reductionRatio = this.rowHeightCurrent / this.rowHeightDefault;
      //            if(type === 'bar') return {height: (this.rowHeightCurrent) + 'px'}
      let margin = Math.ceil(this.timeBarMargin * reductionRatio);
      if (type === "bar") return this.rowHeightCurrent - margin;
      let padding = Math.ceil(this.timeBarPadding * reductionRatio);
      if (type === "outer") return this.rowHeightCurrent - 0;
      if (type === "inner") return this.rowHeightCurrent - padding;
    },
    GetTimeBarText() {
      let reductionRatio = 1 / 3 + this.rowHeightCurrent / (this.rowHeightDefault * 1.5);
      //            if(type === 'bar') return {height: (this.rowHeight) + 'px'}
      return { "font-size": 17 * reductionRatio + "px" };
    },
    GetRedBarLeft(employee) {
      if (((employee.not_logged_in && employee.notLoggedInMinutes <= 45) || (!employee.not_logged_in && employee.additionalPauseMandatory && !this.loadingPauses)) && employee.rotas.filter((r) => r.show)[0]) {
        let midPoint = this.timeframe * 0.5 * 3600 * this.secondPerPixel;
        if (midPoint - employee.rotas[0].rota_left > 30) return employee.rotas.filter((r) => r.show)[0].rota_left + "px";
        else return midPoint - 30 + "px";
      }
      return "";
    },
    GetRedBarWidth(employee) {
      if (((employee.not_logged_in && employee.notLoggedInMinutes <= 45) || (!employee.not_logged_in && employee.additionalPauseMandatory && !this.loadingPauses)) && employee.rotas.filter((r) => r.show)[0]) {
        let midPoint = this.timeframe * 0.5 * 3600 * this.secondPerPixel;
        if (midPoint - employee.rotas[0].rota_left > 30) return (this.timestamp - employee.rotas.filter((r) => r.show)[0].rota_start) * this.secondPerPixel + "px";
        else return 30 + "px";
      }
      return "30px";
    },
    GetRedBarTextLeft(employee) {
      if (((employee.not_logged_in && employee.notLoggedInMinutes <= 45) || (!employee.not_logged_in && employee.additionalPauseMandatory && !this.loadingPauses)) && employee.rotas.filter((r) => r.show)[0]) {
        let midPoint = this.timeframe * 0.5 * 3600 * this.secondPerPixel;
        if (midPoint - employee.rotas[0].rota_left > 30) return (this.timestamp - employee.rotas.filter((r) => r.show)[0].rota_start) * this.secondPerPixel + "px";
        else return 30 + "px";
      }
      return "30px";
    },
    GetTimeBarIcon() {
      let reductionRatio = 1 / 3 + this.rowHeightCurrent / (this.rowHeightDefault * 1.5);
      //            if(type === 'bar') return {height: (this.rowHeight) + 'px'}
      return { "font-size": 24 * reductionRatio + "px" };
    },
    GetPauseIconSize() {
      let reductionRatio = 1 / 3 + this.rowHeightCurrent / (this.rowHeightDefault * 1.5);
      //            if(type === 'bar') return {height: (this.rowHeight) + 'px'}
      return { "font-size": 16 * reductionRatio + "px" };
    },
    GetEmployeeNameMargin() {
      let size = this.rowHeightCurrent - this.employeePadding * 2;
      return { left: size + this.employeePadding * 2 + "px" };
    },
    getTimestamp: function() {
      let view = this;

      let url = "/api/isMaintenance";
      axios.get(url).then((response) => {
        if (response.status && response.status == "200" && response.data && response.data.message && response.data.message == "true") {
          view.MAINTENANCE = true;
        }
      });

      view.timestamp = moment().unix();
      view.calculateTimeLines();
      /*
            let url = "/api/pausenplan/time";
            url += "?id=" + this.pausenplanId;
            url += "&token=" + this.pausenplanToken;
            axios.get(url).then(response => {
                view.timestamp = response.data.data;
                const date = moment.unix(view.timestamp);
                view.calculateTimeLines();
            });
            */
    },
    getPausenplanOptions: function() {
      let view = this;
      let url = "/api/pausenplan/options";
      url += "?id=" + this.pausenplanId;
      url += "&token=" + this.pausenplanToken;
      if (this.pausenplanId === null) {
        url = "/api/pausenplan/options";
        url += "?publicId=" + this.publicPausenplanId;
        url += "&publicToken=" + this.publicPausenplanToken;
      }

      axios.get(url).then((response) => {
        view.optionsCallDidRun = true;
        let d = response.data.data[0];
        view.setPausenplanOptions(d);
      });
    },
    setPausenplanOptions: function(d) {
      let view = this;
      view.timeframe = d.timeRange;
      view.showFinished = d.showCompletedShifts;
      view.showRestingArea = d.showRestingArea;
      d.showCompletedShifts ? (view.hideFinishedWhichAreOlderThanHours = d.hideCompletedShiftsAfterHours) : (view.hideFinishedWhichAreOlderThanHours = 0);
      d.showFutureShifts ? (view.showFuture = d.showFutureShiftsWithinHours) : (view.showFuture = 0.1);
      view.actualShiftEndedOpacity = d.visibilityCompletedShifts / 100.0;
      view.actualShiftNotStartedOpacity = d.visibilityFutureShifts / 100.0;
      view.showHourlyDivisions = d.showHelpBarsUnderShifts;
      view.usePositions = d.usePositions;
      view.showDefaultPause = d.showDefaultPause;
      view.countryId = d.countryId;
      view.spotActualShiftColorEnabled = d.colorizeLayers;
      view.spotPlanShiftColorEnabled = d.colorizeLayers;
      if(!view.usePositions){
        view.spotActualShiftColorEnabled = false;
        view.spotPlanShiftColorEnabled = false;
      }
      view.pausenplanIdentifier = d.number;
      view.customPositions = d.positions;

      view.positions = JSON.parse(JSON.stringify(view.positionsOriginal));
      for (let i in view.positions) {
        let tmpPos = view.positions[i];
        for (let j in view.customPositions) {
          let tmpPosCustom = view.customPositions[j];
          if (tmpPos.id == tmpPosCustom.id) {
            tmpPos.sort = tmpPosCustom.sort;
            tmpPos.active = tmpPosCustom.active;
          }
        }
      }
      view.positions.sort(function sort(a, b) {
        return parseInt(b.sort) < parseInt(a.sort) ? 1 : parseInt(b.sort) > parseInt(a.sort) ? -1 : 0;
      });
    },
    getPositions: function() {
      let view = this;
      let url = "/api/pausenplan/positions";
      url += "?id=" + this.pausenplanId;
      url += "&token=" + this.pausenplanToken;
      if (this.pausenplanId === null) {
        url = "/api/pausenplan/positions";
        url += "?publicId=" + this.publicPausenplanId;
        url += "&publicToken=" + this.publicPausenplanToken;
      }
      axios
        .get(url)
        .then((response) => {
          view.retries = 1;
          view.positionsCallDidRun = true;
          view.positions = response.data.data;

          let notCategorizedPosition = {};
          notCategorizedPosition.active = 1;
          notCategorizedPosition.color = "#777777";
          notCategorizedPosition.isMain = 1;
          notCategorizedPosition.md_id = "00000000-0000-0000-0000-000000000000";
          notCategorizedPosition.name = "Individual";
          notCategorizedPosition.sort = 9998;
          view.positions.push(notCategorizedPosition);

          let externalPosition = {};
          externalPosition.active = 1;
          externalPosition.color = "#bd6c17";
          externalPosition.isMain = 1;
          externalPosition.md_id = "10000000-0000-0000-0000-000000000000";
          externalPosition.name = "Extern";
          externalPosition.sort = 9999;
          view.positions.push(externalPosition);

          view.positions.sort(function sort(a, b) {
            return b.sort < a.sort ? 1 : b.sort > a.sort ? -1 : 0;
          });
          view.positionsOriginal = JSON.parse(JSON.stringify(response.data.data));

          if (view.optionsCallDidRun) {
            if(view.usePositions){
              view.positions = JSON.parse(JSON.stringify(view.positionsOriginal));
              for (let i in view.positions) {
                let tmpPos = view.positions[i];
                for (let j in view.customPositions) {
                  let tmpPosCustom = view.customPositions[j];
                  if (tmpPos.id == tmpPosCustom.id) {
                    tmpPos.sort = tmpPosCustom.sort;
                    tmpPos.active = tmpPosCustom.active;
                  }
                }
              }
              view.positions.sort(function sort(a, b) {
                return parseInt(b.sort) < parseInt(a.sort) ? 1 : parseInt(b.sort) > parseInt(a.sort) ? -1 : 0;
              });
            }else{
              view.positions = [];
              let notCategorizedPosition = {};
              notCategorizedPosition.active = 1;
              notCategorizedPosition.color = "#777777";
              notCategorizedPosition.isMain = 1;
              notCategorizedPosition.md_id = "00000000-0000-0000-0000-000000000000";
              notCategorizedPosition.name = "";
              notCategorizedPosition.sort = 9998;
              view.positions.push(notCategorizedPosition);

              let externalPosition = {};
              externalPosition.active = 1;
              externalPosition.color = "#bd6c17";
              externalPosition.isMain = 1;
              externalPosition.md_id = "10000000-0000-0000-0000-000000000000";
              externalPosition.name = "Extern";
              externalPosition.sort = 9999;
              view.positions.push(externalPosition);

              view.positions.sort(function sort(a, b) {
                return b.sort < a.sort ? 1 : b.sort > a.sort ? -1 : 0;
              });
            }
          }

          this.getEmployees();
        })
        .catch((error) => {
          view.retries++;
          if (view.retries > 60) {
            view.retries = 60;
          }
          setTimeout(function() {
            view.getPositions();
          }, 5000 * view.retries);
        });
    },
    getEmployees: function() {
      let view = this;
      let url = "/api/pausenplan/employees";
      url += "?id=" + this.pausenplanId;
      url += "&token=" + this.pausenplanToken;
      if (this.pausenplanId === null) {
        url = "/api/pausenplan/employees";
        url += "?publicId=" + this.publicPausenplanId;
        url += "&publicToken=" + this.publicPausenplanToken;
      }
      axios
        .get(url)
        .then((response) => {
          view.retries = 1;
          view.employees = response.data.data;
          for (let i in view.employees) {
            let employee = view.employees[i];

            employee.extern = false;
            employee.not_logged_in = false;

            //employee.firstname = view.randomFirstnames[Math.floor(Math.random() * 25)];
            //employee.lastname = view.randomLastnames[Math.floor(Math.random() * 25)];

            view.employeeMap[employee.id] = employee;
          }
          this.getRotas();
        })
        .catch((error) => {
          view.retries++;
          if (view.retries > 60) {
            view.retries = 60;
          }
          setTimeout(function() {
            view.getEmployees();
          }, 5000 * view.retries);
        });
    },
    getRotas: function() {
      let view = this;
      let url = "/api/pausenplan/rotas";
      url += "?id=" + this.pausenplanId;
      url += "&token=" + this.pausenplanToken;
      if (this.pausenplanId === null) {
        url = "/api/pausenplan/rotas";
        url += "?publicId=" + this.publicPausenplanId;
        url += "&publicToken=" + this.publicPausenplanToken;
      }
      axios
        .get(url)
        .then((response) => {
          view.retries = 1;
          view.rotas = [];
          view.hiddenRotas = [];
          for (let i in response.data.data) {
            let rota = response.data.data[i];

            rota.taken = false;
            rota.extern = false;
            if (rota.externalStoreId == null) {
              view.rotas.push(rota);
            } else {
              view.hiddenRotas.push(rota);
            }
          }
          this.getExternal();
        })
        .catch((error) => {
          view.retries++;
          if (view.retries > 60) {
            view.retries = 60;
          }
          setTimeout(function() {
            view.getRotas();
          }, 5000 * view.retries);
        });
    },
    getExternal: function() {
      let view = this;
      let url = "/api/pausenplan/external";
      url += "?id=" + this.pausenplanId;
      url += "&token=" + this.pausenplanToken;
      if (this.pausenplanId === null) {
        url = "/api/pausenplan/external";
        url += "?publicId=" + this.publicPausenplanId;
        url += "&publicToken=" + this.publicPausenplanToken;
      }
      axios
        .get(url)
        .then((response) => {
          view.retries = 1;
          let positions = response.data.data.positions;

          let externalSpotMap = [];
          for (let i in positions) {
            externalSpotMap[positions[i].md_id] = positions[i];
          }

          let rotas = response.data.data.rotas;
          for (let i in rotas) {
            let rota = rotas[i];
            rota.taken = false;
            rota.extern = true;

            let tryToMatchName = true;
            if (tryToMatchName) {
              if (externalSpotMap[rota.spot_id] != undefined && externalSpotMap[rota.spot_id] != null) {
                for (let j in view.positions) {
                  if (view.positions[j].name == externalSpotMap[rota.spot_id].name) {
                    rota.spot_id = view.positions[j].md_id;
                    break;
                  }
                }
              }
            }

            view.rotas.push(rota);
          }

          view.shifts = [];
          let shifts = response.data.data.shifts;
          for (let i in shifts) {
            let shift = shifts[i];
            shift.taken = false;
            shift.extern = true;
            view.shifts.push(shift);
          }

          let employees = response.data.data.employees;
          for (let i in employees) {
            let employee = employees[i];
            employee.extern = true;
            view.employees.push(employee);
            employee.not_logged_in = false;
            view.employeeMap[employee.id] = employee;
          }

          this.getShifts();
        })
        .catch((error) => {
          view.retries++;
          if (view.retries > 60) {
            view.retries = 60;
          }
          setTimeout(function() {
            view.getExternal();
          }, 5000 * view.retries);
        });
    },
    getShifts: function() {
      let view = this;
      let url = "/api/pausenplan/shifts";
      url += "?id=" + this.pausenplanId;
      url += "&token=" + this.pausenplanToken;
      if (this.pausenplanId === null) {
        url = "/api/pausenplan/shifts";
        url += "?publicId=" + this.publicPausenplanId;
        url += "&publicToken=" + this.publicPausenplanToken;
      }
      axios
        .get(url)
        .then((response) => {
          view.retries = 1;
          for (let i in response.data.data) {
            let shift = response.data.data[i];

            let pushMe = true;
            for (let j in view.hiddenRotas) {
              let hiddenRota = view.hiddenRotas[j];
              if (shift.id_rota == hiddenRota.id) {
                pushMe = false;
                break;
              }
            }

            if (pushMe) {
              shift.taken = false;
              shift.extern = false;
              view.shifts.push(shift);
            }
          }
          for (let i in view.positions) {
            let position = view.positions[i];
            position.employees = view.getEmployeesForPosition(position);
            view.resizeTimelines();
          }
          this.getPauses();
        })
        .catch((error) => {
          view.retries++;
          if (view.retries > 60) {
            view.retries = 60;
          }
          setTimeout(function() {
            view.getShifts();
          }, 5000 * view.retries);
        });
    },
    getPauses: function() {
      let view = this;

      let shiftIds = [];
      for (let i in view.shifts) {
        let shift = view.shifts[i];
        shiftIds.push(shift.id);
      }
      let url = "/api/pausenplan/pauses?shiftIds=" + shiftIds.join(",");
      url += "&id=" + this.pausenplanId;
      url += "&token=" + this.pausenplanToken;
      if (this.pausenplanId === null) {
        url = "/api/pausenplan/pauses?shiftIds=" + shiftIds.join(",");
        url += "&publicId=" + this.publicPausenplanId;
        url += "&publicToken=" + this.publicPausenplanToken;
      }
      axios
        .get(url)
        .then((response) => {
          view.retries = 1;
          view.pauses = response.data.data;
          view.pauseCallbackReceived = true;
        })
        .catch((error) => {
          view.retries++;
          if (view.retries > 60) {
            view.retries = 60;
          }
          setTimeout(function() {
            view.getPauses();
          }, 5000 * view.retries);
        });
    },

    componentToHex: function(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },
    rgbToHex: function(r, g, b) {
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    },
    hexToRgb: function(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null;
    }
  }
};
</script>

<style scoped>
.pausenplan {
  width: calc(100vw - 50px);
  position: relative;
  height: 100vh;
}

.overlayMain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999998;
  background-color: rgba(33, 33, 33, 0.7);
}
.overlayText {
  z-index: 9999999;
  position: fixed;
  font-size: 256px;
  font-weight: bold;
  letter-spacing: 0px;
  height: 300px;
  line-height: 300px;
  margin-top: calc(50% - 300px);
  margin-left: calc(50% - 250px);
  width: 500px;
  color: #282828;
  text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff, 0 0 55px #fff;
  text-align: center;
}

.content {
  width: calc(100% - var(--content-margin) * 2);
  width: calc(100vw - 30px);
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}
.header {
  width: 100%;
  height: var(--header-height);
  margin: 0;
  padding: 0;
  background-color: rgba(1, 110, 50, 0);
}

.position {
  position: relative;
  position: relative;
  padding-left: 0px;
  width: 100vw;
}
.position .outer:first-child {
  position: absolute;
  left: 290px;
  top: 0;
  height: 100%;
  width: 20px;
}

.position > .outer > .header {
  width: 20px;
  font-size: 12px;
  height: 100%;
  line-height: 30px;
  font-weight: bold;
  /*text-shadow: -2px 0 2px #444, 0 2px 2px #444, 2px 0 2px #444, 0 -2px 2px #444;*/
  color: #fff;
  letter-spacing: 1px;
  display: inline-block;
  overflow: hidden;
  font-family: DistrictProBook, Arial, Helvetica, sans-serif;
  position: absolute;
  writing-mode: vertical-rl;
  /* text-orientation: upright; */
  text-orientation: mixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transform: rotate(180deg);
  z-index: 10;
  white-space: nowrap;
}

.position > .outer > .employee {
  width: 300px;
  /* margin-left: 15px; */
  font-size: 15px;
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
  z-index: 1;
  box-shadow: inset 0 1px 0 0 #e5e5e5;
}
.position > .outer > .employee > .bg {
  width: 250px;
  height: 100%;
  margin-left: 50px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  transition: background-color 2000ms linear;
  position: relative;
}

.lineLeft {
  position: absolute;
  top: calc(var(--content-margin) + var(--header-height) + 10px);
  left: calc(300px + var(--content-margin));
  border-left: 1px solid #e5e5e5;
  height: calc(100% - (var(--content-margin) * 2 + var(--header-height)));
  width: 0px;
  display: none;
}
.lineRight {
  position: absolute;
  top: calc(var(--content-margin) + var(--header-height) + 10px);
  right: -20px;
  border-left: 1px solid #e5e5e5;
  height: calc(100% - (var(--content-margin) * 2 + var(--header-height)));
  width: 0px;
}
.timeLine {
  position: absolute;
  top: calc(var(--content-margin) + var(--header-height) + 30px);
  left: 307px;
  right: 0;
  margin: auto;
  border-left: 3px solid #808080;
  border-right: 3px solid #808080;
  width: 0px;
  height: 100vh;
  z-index: 100;
}
.timeDiv {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(var(--content-margin) + var(--header-height) - var(--header-hour-height) + 10px);
  left: 307px;
  right: 0;
  margin: auto;
  height: 40px;
  width: 200px;
  background-color: rgba(128, 128, 128, 0.9);
  text-align: center;
  line-height: calc(var(--header-height) - 10px);
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 2px;
  font-family: monospace;
  z-index: 10;
}
.timeDoublePoint {
  transition: opacity 750ms linear;
}

.alertMessageIcon {
  transition: opacity 1750ms linear;
}

.linesMiddle,
.linesMiddleDiv,
.linesMiddleHourDiv {
  z-index: 1 !important;
}
.linesMiddle {
  position: absolute;
  top: calc(var(--content-margin) + var(--header-height));
  left: calc(300px + var(--content-margin));
  /*border-left: 1px solid #eee;
            border-right: 1px solid #eee;*/
  background-color: rgba(239, 239, 239, 0.5);
  height: calc(100% - (var(--content-margin) * 2 + var(--header-height)));
  width: 2px;
  z-index: -1 !important;
}

.linesMiddleDiv {
  position: absolute;
  margin-top: -20px;
  top: calc(var(--content-margin));
  left: calc(300px + var(--content-margin));
  height: calc(var(--header-height));
  text-align: center;
}
.linesMiddleHourDiv {
  position: absolute;
  top: calc(var(--content-margin) + var(--header-height) - var(--header-hour-height) - 10px);
  left: calc(300px + var(--content-margin));
  height: 40px;
  text-align: center;
  font-family: DistrictProBook;
  font-size: 12px;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content > .header {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 150px;
  height: initial;
  margin-bottom: 30px;
}

.content > .header .header-image {
  position: absolute;
  bottom: 10px;
  left: 55px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.content > .header .header-text {
  position: absolute;
  bottom: 10px;
  left: 115px;
  width: 177px;
  height: 50px;
  overflow: hidden;
}

.time-backdrop {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 40px;
  width: calc(100% - 290px);
}

.employee-column {
  height: 50px;
  position: relative;
}

.firstname {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  margin: auto;
  margin-top: 6px;
  font-family: DistrictProBook;
  font-size: 14px;
  height: fit-content;
  color: #404040;

  padding-left: 1px;
  white-space: nowrap;
}

.lastname {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  margin: auto;
  margin-bottom: 6px;
  font-family: DistrictProBold;
  font-size: 14px;
  height: fit-content;
  color: #404040;
  white-space: nowrap;
}

.name {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
  margin: auto;
  font-family: DistrictProBook;
  font-size: 14px;
  height: fit-content;
  color: #404040;
  padding-left: 1px;
  white-space: nowrap;
}

.name .firstname,
.name .lastname {
  display: inline;
}

.timebar {
  display: inline-block;
  overflow: hidden;
  width: calc(100% - 315px);
  height: 40px;
  z-index: 1;
  background-color: transparent !important;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;

  margin-left: 10px;
}

.pause {
  background-color: rgb(229, 196, 31);
  text-align: center;
  color: white;
  text-align: left;
  /* padding-left: 5px; */
  position: absolute;
  height: 23px;
  top: 0;
  bottom: 0;
  font-size: 18px;
  float: left;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shift {
  position: absolute;
  height: 23px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #708d2b;
}

.shift .start,
.shift .end {
  position: absolute;
  z-index: 999;
  text-align: left;
  color: white;
  font-size: 14px;
  font-family: DistrictProBook;
  top: -1px;
  bottom: 0;
  margin: auto;
  height: fit-content;
  padding: 0 10px;
}

.shift .start {
  left: 3px;
}

.shift .end {
  right: 3px;
  text-align: right;
}

.rotation {
  position: absolute;
  height: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #e5e5e5;
}

.legalcheck {
  position: absolute;
  height: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #e5e5e5;
}

.pause,
.shift {
}

.shift-hour {
  position: absolute;
  z-index: 999;
  text-align: left;
  color: white;
  font-size: 14px;
  font-family: DistrictProBook;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  padding-left: 10px;
}

.pause-container {
  position: absolute;
  right: 20px;
  top: calc(var(--content-margin) + var(--header-height) - var(--header-hour-height) - 10px);
  width: 150px;
  height: fit-content;
  z-index: 10;
  display: block;
  box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.4);
  background: white;
}

.pause-container .header {
  height: 40px;
  position: relative;
  width: 100%;
  background: rgb(229, 196, 31);
  text-align: center;
  font-family: DistrictProBold;
  font-size: 25px;
  color: #404040;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pause-container .pauses {
  background: #f2f2f2;
  left: 0;
  top: 40px;
}

.pause-container .employee-pause {
  width: 150px;
  height: 150px;
  float: left;
  position: relative;
}
.pause-container .employee-pause .circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 120px;
  height: 120px;
  border-radius: 200px;
  background: #e5c41f;
  overflow: hidden;
}
.pause-container .employee-pause .circle .cover-left,
.pause-container .employee-pause .circle .cover-right,
.pause-container .employee-pause .circle .cover-mid {
  height: 100%;
  width: 50%;
  position: absolute;
  background: #e5e5e5;
}
.pause-container .employee-pause .circle .cover-left {
  top: 0;
  left: 25%;
}

.pause-container .employee-pause .circle .cover-mid {
  top: 0;
  left: 25%;
  background: #e5c41f;
  transform: translate(50%, 0%);
}

.pause-container .employee-pause .circle .cover-right {
  top: 0;
  left: 25%;
}

.pause-container .employee-pause .picture,
.pause-container .employee-pause .timer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 90px;
  height: 90px;
  border-radius: 200px;
  overflow: hidden;
  background: #ccc url("/images/avatar.png");
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.pause-container .employee-pause .picture {
  animation: alternate;
  animation-duration: 10s;
  animation-name: fade-picture;
  animation-iteration-count: infinite;
}

.pause-container .employee-pause .timer:not(.fixed) {
  animation: alternate;
  animation-duration: 10s;
  animation-name: fade-timer;
  animation-iteration-count: infinite;
}

@keyframes fade-picture {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-timer {
  0% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.pause-container .employee-pause .icon {
  background: #e5c41f;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 40px;
  height: 40px;
  border-radius: 200px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pause-container .employee-pause .icon.fa-utensils {
  font-size: 20px;
}

.pause-container .employee-pause .timer {
  background: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-family: DistrictProBold;
  color: white;
  line-height: 0;
}

.employee.overtime .bg img {
  box-shadow: 0 0 0 3px #e51550;
}

.employee.pause .bg img {
  box-shadow: 0 0 0 3px #e5c41f;
}

.bg .fas,
.bg .pause-time {
  display: none;
}

.bg .fa-coffee,
.bg .fa-utensils {
  display: block;
  font-size: 12px;
  color: #777;
  display: block;
  position: absolute;
  top: 2px;
  right: 65px;
  bottom: 0;
  margin: auto;
  height: fit-content;
  z-index: 2;
}

.overtime-show .fas,
.pause-show .pause-time {
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  height: fit-content;
  color: #777;
  z-index: 2;
}

.overtime-show .fas {
  height: 25px;
  width: 25px;
  border-radius: 200px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-top: 1px;
  z-index: 2;
}

.pause-show .pause-time {
  font-size: 12px;
  color: #777;
  font-family: DistrictProBold;
  z-index: 2;
}

.fa-clock:before {
  content: "\f017";
}

.shift-overtime {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #e51550;
}

.name-shadow {
  position: absolute;
  right: 0;
  top: 1px;
  width: 25px;
  box-shadow: inset -20px 0 5px 0px white;
  height: calc(100% - 2px);
  z-index: 1;
}

.pause-show .name-shadow {
  width: 90px;
  box-shadow: inset -85px 0 5px 0px white;
}

.overtime-show .name-shadow {
  width: 55px;
  box-shadow: inset -45px 0 5px 0px white;
}

.maintenance {
  width: calc(100vw);
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #282828;
}


.stripes{
background: repeating-linear-gradient(
  -50deg,
  #D18686 ,
  #D18686 10px,
  #F3E7E7 10px,
  #F3E7E7 20px
);
border-left: 3px solid #B64444;
}

.legalRest11{
  font-weight: bold;
  text-shadow: -2px -2px 10px #000;
  text-shadow: 2px -2px 10px #000;
  text-shadow: -2px 2px 10px #000;
  text-shadow: 2px 2px 10px #000;

  position: absolute;
  z-index: 999;
  text-align: left;
  color: white;
  font-size: 14px;
  font-family: DistrictProBook;
  top: -1px;
  bottom: 0;
  height: fit-content;
}
</style>
