<template>
  <div id="app">
    <Pausenplan/>
  </div>
</template>

<script>
import Pausenplan from './components/Pausenplan.vue'

export default {
  name: 'App',
  components: {
    Pausenplan
  }
}
</script>

<style>

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html, body{
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    overflow: hidden;
}
#app {
}
</style>
